export default {
	components: [
		{
			type: "product",
			title: "Notre plat du jour ⤵️",
			desc: "",
			price: 0,
			img_url: false
		},
		{
			type: "product",
			title: "Couscous royal 👑",
			desc: "Couscous, agneau, poulet, \nmergez et légumes",
			price: 13.50,
			img_url: 'https://nmoreaux.com/things/micro/couscous.jpg'
		},
		{
			type: "product",
			title: "Notre carte ⤵️",
			desc: "",
			price: 0,
			img_url: false
		},
		{
			type: "product",
			title: "🥩🍟 Steak frites",
			desc: "Steak du boucher et frites maison",
			price: 13,
			img_url: 'https://nmoreaux.com/things/micro/sf.jpg'
		},
		{
			type: "product",
			title: "🍝 Spagettis bolognaise",
			desc: "Viande hachée du boucher",
			price: 11.50,
			img_url: 'https://nmoreaux.com/things/micro/sb.jpg'
		},
		{
			type: "product",
			title: "🥗 Salade du jardin",
			desc: "Garniture selon arrivages",
			price: 5.50,
			img_url: 'https://nmoreaux.com/things/micro/salade.jpg'
		}
		// {
		// 	type: "product",
		// 	title: "Produit sans photo",
		// 	desc: "Détail du produit sans photo",
		// 	price: 99999.99,
		// 	img_url: false
		// }
	],
	infos: {
		name: "La Paillotte 🌴",
		phone: "03 24 30 92 79",
		activity: "Café Restaurant",
		street_adress: "Rue de la gare",
		postal_code: "08240",
		locality: "Buzancy",
		country: "France",
		notes: "Ouvert du lundi au vendredi et le dimanche\n11h30-14h00, 18h30-21h00",
		img_url: 'https://nmoreaux.com/things/micro/facade.jpg'
	}
};
