import React,{useState,useReducer,useContext,useEffect,useRef} from 'react';
import PinInput from 'react-pin-input';
import {BrowserRouter as Router,Route,Link,Redirect,Switch} from "react-router-dom";
import {Button,Header,Footer,Space,Product,ComponentsList,ReorderComponentsList} from './Components.js';
import './App.scss';
import db_default from './db_default.js';
import xhr from 'xhr';



let mobile_device = false;
let apple_device = false;
if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
	mobile_device = true;
}
if( /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent) ) {
	apple_device = true;
}
const init_state = {};
let lastid = 0;
let password = false;
const fs_username = 'micro-'+window.location.hostname;
const db_endpoint = 'https://firestarter.nmoreaux.com/v2';

// ----- debug ----
if(process.env.NODE_ENV === 'development'){
	password = '0000';
}




function dbLoad(dispatch){
	xhr({
		url:db_endpoint+'?username='+fs_username+'&filename=db',
		useXDR: true,
		json: true
	},(err,resp,body)=>{
		dispatch({
			type: 'load_db',
			db: (resp.statusCode == 200)?body:db_default
		});
	})
}
function dbTestPassword(pin,dispatch){
	xhr({
		url:db_endpoint,
		json: true,
		useXDR: true,
		method: 'POST',
		body: {
			action: 'test_password',
			username: fs_username,
			password: pin
		}
	},(err,resp,body)=>{
		if(resp.statusCode == 200){
			password = pin;
			dispatch({type:'authed',value:true});
		}else{
			alert('Mauvais code !');
		}
	})
}
function dbSave(data){
	if(password){


		const obj = Object.assign({},data,{authed:null,loaded:null});
		const data_b64 = Buffer.from(JSON.stringify(obj)).toString("base64");
		xhr({
			url:db_endpoint,
			json: true,
			useXDR: true,
			method: 'POST',
			body: {
				action: 'set_file',
				username: fs_username,
				password: password,
				filename: 'db',
				data: 'data:application/json;base64,'+data_b64,
				content_type: 'application/json'
			}
		},(err,resp,body)=>{
			if(resp.statusCode == 200){

			}
		});
	}
}

function stateReducer(old_state, action) {
	let state = Object.assign({},old_state);

	switch (action.type) {
		case 'authed':
			state.authed = action.value
			break;
		case 'remove_component':
			state.components = [...state.components.filter(c => c.id != action.id)];
			break;
		case 'reorder_components':
			let new_components = [],comp;
			for (var i = 0; i < action.ids.length; i++) {
				comp = state.components.find(c => c.id == action.ids[i]);
				new_components.push(comp);
			}
			state.components = new_components;
			break;
		case 'set_prop_in':
			action.in[action.prop] = action.value;
			state = Object.assign({},old_state);
			break;
		case 'load_db':
			let loaded_state = action.db;
			loaded_state.authed = password?true:false;
			loaded_state.loaded = true;
			loaded_state.components.forEach(function(c,i){
				c.id = i;
			});
			lastid = (loaded_state.components.length)-1;
			document.title = loaded_state.infos.name+' - '+loaded_state.infos.activity+' à '+loaded_state.infos.locality;
			state = loaded_state;
			break;
		case 'insert_product_at':
			lastid++;
			const new_component = {
				type: "product",
				id: lastid,
				title: "Nouveau produit",
				desc: "",
				price: 0,
				img_url: false
			};
			const index = action.i_before+1;
			state.components.splice(index,0,new_component);
			break;
		default:
			throw new Error();
	}
	if(action.type !== 'load_db' && action.type !== 'authed'){
		dbSave(state);
	}
	return state;
}




const StateDispatch = React.createContext(null);

function checkAuth(state){
	if(!state.authed){
		return <Redirect to='/identification' />
	}
}
function scrollTop(){
	window.scrollTo(0, 0);
}




function Auth(props){
	const dispatch = useContext(StateDispatch);
	function tryAuth(pin){
		dbTestPassword(pin,dispatch);
	}
	function vibrate(){
		navigator.vibrate = navigator.vibrate || navigator.webkitVibrate;
		if (navigator.vibrate) {
			navigator.vibrate(30);
		}
	}
	// <p>Votre code se trouve sur votre facture <a href="http://monsitemoinscher.com" target="_blank">monsitemoinscher.com</a></p>
	return <div className="auth">
		{props.state.authed && <Redirect to='/modifier' />}
		<h2>Saisissez votre code</h2>
		<PinInput length={4} focus={true} onComplete={tryAuth} onChange={vibrate} />
		<p>Votre code vous a été communiqué lors de la création de votre site internet</p>
		<div className="max-width-container no-padding">
			<Button to="/">Pour retourner sur votre site sans le modifier</Button>
		</div>
	</div>
}

//modp+modinfos+suppp+ajjp

function Public(props){
	useEffect(scrollTop);


	const navigator_share = (navigator.share);
	function share(){
		if(navigator.share){
			navigator.share({
				text: document.title,
				url: window.location.href
			});
		}
	}

	return <div>
		<Header fixed infos={props.state.infos} infos={props.state.infos} />
		<div className="max-width-container">
			<ComponentsList components={props.state.components} statedispatchcontext={StateDispatch} />
		</div>
		<Footer infos={props.state.infos} />

		<div className="max-width-container no-padding">
			<Button primary to="/modifier">Pour modifier ce site</Button>
		</div>

		<div className="share-block">
			<div className="inner">
				<div className="max-width-container no-padding">
					{navigator_share&&<Button primary onClick={share}>Pour partager ce site avec vos amis</Button>}
					{!navigator_share&&<Button primary to="/partager">Pour partager ce site avec vos amis</Button>}
				</div>
			</div>
		</div>
	</div>
}


function ShareWebsite(props){
	const domain = window.location.protocol + '//' + window.location.hostname;
	const text = document.title;

	function facebook(){
		let url = "https://www.facebook.com/sharer.php?u=";
		url += encodeURIComponent(domain);
		window.open(url,"_blank");
	}
	function sms(){
		let url = "sms:?body=";
		if(apple_device){
			url = "sms:0600000000&body=";
		}
		url += encodeURIComponent(text+' - '+domain);
		window.open(url,"_system");
	}
	function email(){
		let url = "mailto:?body=";
		url += encodeURIComponent(text+' - '+domain);
		window.open(url,"_system");
	}

	return (<div className="edit">
		<h2>Merci de partager le site <span className="nowrap">{props.state.infos.name}</span> à vos amis</h2>
		<div className="max-width-container no-padding">
			<Button primary onClick={facebook}>Pour partager sur Facebook</Button>
			<Button primary onClick={sms}>Pour partager par SMS</Button>
			<Button primary onClick={email}>Pour partager par Email</Button>
			<Button to="/">Pour retourner sur <span>le site</span></Button>
		</div>
	</div>);
}


function Edit(props){
	useEffect(scrollTop);

	return <div className="edit">
		{checkAuth(props.state)}


		<h2>Que souhaitez-vous faire ?</h2>
		<div className="max-width-container no-padding">
			<Button primary to="/modifier-produit">Modifier un produit</Button>
			<Button primary to="/ajouter-produit">Ajouter un produit</Button>
			{props.state.components.length > 1&&<Button primary to="/reorganiser-produit">Réorganiser vos produits</Button>}

			{props.state.components.length > 0&&<Button primary to="/cacher-produit">Cacher temporairement un produit</Button>}
			{props.state.components.length > 0&&<Button primary to="/supprimer-produit">Supprimer <span>un produit</span></Button>}

			<Button primary to="/modifier-coordonnees">Modifier vos coordonnées</Button>

			<Button to="/">Retourner sur <span>votre site</span></Button>
		</div>

	</div>
}
function EditProducts(props){

	return <div className="edit">
		{checkAuth(props.state)}
		{props.mode==='mask-product'&&<p>Cette page vous permet de cacher temporairement vos plats du jour et autres produits saisonniers</p>}

		<div className="max-width-container">
			<ComponentsList components={props.state.components} mode={props.mode} statedispatchcontext={StateDispatch} />
		</div>

		<div className="max-width-container no-padding">
			<Button onClick={props.history.goBack}>Modifier autre chose</Button>
			<Button to="/">Retourner sur <span>votre site</span></Button>
		</div>

	</div>
}

function DeleteProduct(props){
	const [confirmed, setConfirmed] = useState(false);
	useEffect(scrollTop);
	const data = props.state.components.find((c)=>{
		return c.id == props.id;
	});
	const dispatch = useContext(StateDispatch);

	if(data && confirmed){
		dispatch({type:'remove_component',id:props.id});
		//return <Redirect to='/cacher-supprimer-produit' />;
		props.history.goBack();
		return (null);
	}else if(data){
		return <div className="edit">
			{checkAuth(props.state)}
			<h2>Souhaitez-vous supprimer définitivement ce produit ?</h2>
			<div className="max-width-container">
				<Product data={data} statedispatchcontext={StateDispatch} />
			</div>
			<div className="max-width-container no-padding">
				<Button red onClick={() => {setConfirmed(true)}}>Pour confirmer la suppression</Button>
				<Button onClick={props.history.goBack}>Pour annuler la suppression</Button>
			</div>
		</div>
	}
	return (null);
}

function ChangePassword(props){
	const [message, setMessage] = useState("En cours...");
	useEffect(()=>{
		xhr({
			url:db_endpoint,
			useXDR: true,
			json: true,
			method: 'POST',
			body: {
				action: 'change_password',
				username: fs_username,
				password: props.old,
				new_password: props.new
			}
		},(err,resp,body)=>{
			setMessage(body.message);
		});
	},[]);
	return <h1>{message}</h1>
}

function EditProduct(props){

	const dispatch = useContext(StateDispatch);
	useEffect(scrollTop);

	const data = props.state.components.find((c)=>{
		return c.id == props.id;
	});

	function toggleSold(){

		dispatch({
			type:'set_prop_in',
			in:data,
			prop:'sold',
			value:(!data.sold)
		});
	}

	return <div className="edit">
		{checkAuth(props.state)}
		<div className="max-width-container">
			<Product data={data} statedispatchcontext={StateDispatch} />
		</div>


		<h2>Que souhaitez-vous faire ?</h2>
		<div className="max-width-container no-padding">
			<Button primary to={'/modifier-produit/'+data.id+'/titre'}>{data.title?'Modifier le':'Ajouter un'} titre</Button>
			<Button primary to={'/modifier-produit/'+data.id+'/prix'}>{data.price?'Modifier le':'Ajouter un'} prix</Button>
			<Button primary to={'/modifier-produit/'+data.id+'/description'}>{data.desc?'Modifier la':'Ajouter une'} description</Button>
			<Button primary onClick={toggleSold}>{data.sold?'Retirer le':'Ajouter un'} bandeau "vendu"</Button>
			<Button primary to={'/modifier-produit/'+data.id+'/photo'}>{data.img_url?'Modifier la':'Ajouter une'} photo</Button>


			<Button onClick={props.history.goBack}>Modifier un autre produit</Button>
			<Button to="/">Retourner sur <span>votre site</span></Button>
		</div>
	</div>
}



function imageSave(content_type,data,callback){
	if(password){
		xhr({
			url:db_endpoint,
			json: true,
			useXDR: true,
			method: 'POST',
			body: {
				action: 'set_file',
				username: fs_username,
				password: password,
				data: data,
				content_type: content_type
			}
		},(err,resp,body)=>{
			if(resp.statusCode == 200){

				callback(body.message);
			}
		});
	}
}


function imageDelete(url,callback){
	if(url){
		xhr({
			url:url+'&password='+password+'&action=delete_file',
			useXDR: true,
			json: true
		},(err,resp,body)=>{
			// Pour le moment, appeler le callback à tous les coups
			callback();
			// if(resp.statusCode == 200){
			// }
		});
	}else{
		callback();
	}
}


function Editor(props){

	const dispatch = useContext(StateDispatch);
	const input = useRef(null);
	const photoinput = useRef(null);
	const photoinput_capture = useRef(null);
	const [confirmed, setConfirmed] = useState(false);
	const [displayphoto, setDisplayphoto] = useState(false);
	const [oldimgurl, setOldimgurl] = useState(false);


	let data,is_component = false;
	if(typeof props.id === 'string'){
		is_component = true;
		data = props.state.components.find((c)=>{
			return c.id == props.id;
		});
	}else {
		data = props.state.infos;
	}

	useEffect(scrollTop);
	useEffect(() => {
		if(input.current){
			input.current.focus();
		}
	});
	function setNewImgUrl(img_url){
		setOldimgurl(data.img_url);
		dispatch({
			type:'set_prop_in',
			in:data,
			prop:'img_url',
			value:img_url
		});
		setDisplayphoto(true);
	}
	async function photoinputchange(evt){
		const file = evt.target.files[0];
		if(file.type.match('image.*')){
			import('browser-image-compression').then((imageCompression) => {
				imageCompression.default(file,{maxSizeMB:0.1,maxWidthOrHeight:1024,maxIteration:3}).then((compressedFile) => {

					// Base64
					// const reader = new FileReader();
					// reader.onload = function(evt){
			    	// 	if(evt.target.readyState == FileReader.DONE){
					// 		setNewImgUrl(evt.target.result);
					// 	}
					// }
			        // reader.readAsDataURL(compressedFile);

					// IMG upload
					const reader = new FileReader();
					reader.onload = function(evt){
						imageSave(compressedFile.type,evt.target.result,(url)=>{
							setNewImgUrl(url);
						});
					}
					reader.readAsDataURL(compressedFile);
				});
			});
		}
	}
	useEffect(() => {
		if(photoinput.current && photoinput_capture.current){
			photoinput.current.onchange = photoinputchange;
			photoinput_capture.current.onchange = photoinputchange;
		}
	});

	let param;
	let param_human_new,param_human,param_machine,article,article_new,neww,setup;
	let param_input,param_default_value;
	const params = {
		'titre': ['le','titre','title'],
		'prix': ['le','prix','price','number'],
		'description': ['la','description','desc','textarea'],
		'nom': ['le','nom','name'],
		'activité': ['lal','activité','activity'],
		'téléphone': ['le','numéro de téléphone','phone'],
		'rue': ['la','rue','street_adress'],
		'commune': ['la','commune','locality'],
		'cp': ['le','code postal','postal_code'],
		'pays': ['le','pays','country'],
		'horaires': ['les','horaires','notes','textarea'],
		'photo': ['la','photo','img_url','photo'],
	}
	if(data && props.param){
		param = props.param;
		if(param in params){
			setup = params[param];
			article = article_new = (setup[0]+' ');
			if(article === 'lal '){
				article = "l'";
				article_new = 'la ';
			}
			neww = 'nouveau';
			if(article_new === 'la '){
				neww = 'nouvelle';
			}
			if(article_new === 'les '){
				neww = 'nouvelles';//WARNING
			}
			param_human_new = article_new+neww+' '+setup[1];
			param_human = article+setup[1];
			param_machine = setup[2];
			param_input = 'text';
			if(typeof setup[3] === 'string'){
				param_input = setup[3];
			}
		}else{
			param = false;
		}
		if(param){
			param_default_value = data[param_machine];
		}
	}

	function save(){
		if(input.current){
			let n_v = input.current.value;
			if(param_input === 'number'){
				n_v = Number(n_v);
			}
			dispatch({
				type:'set_prop_in',
				in:data,
				prop:param_machine,
				value:n_v
			});

			setConfirmed(true);
			props.history.goBack();
		}
	}

	function savephoto(){
		imageDelete(oldimgurl,()=>{
			setConfirmed(true);
			props.history.goBack();
		});
	}
	function dontsavephoto(){
		imageDelete(data.img_url,()=>{
			dispatch({
				type:'set_prop_in',
				in:data,
				prop:'img_url',
				value:oldimgurl
			});
			setDisplayphoto(false);
		});
	}

	const editable = (param && !confirmed);
	if(editable && param_input !== 'photo'){
		return <div className="edit">
			{checkAuth(props.state)}

			<h2>Saisissez {param_human_new} ci-dessous :</h2>
			<div className="max-width-container">
				{(param_input === 'number') && <input type="number" ref={input} step="0.01" defaultValue={param_default_value} />}
				{(param_input === 'text') && <input type="text" ref={input} defaultValue={param_default_value} />}
				{(param_input === 'textarea') && <textarea ref={input} defaultValue={param_default_value}></textarea>}
			</div>
			<div className="max-width-container no-padding">


				<Button primary onClick={save}>Pour enregistrer {param_human_new}</Button>

				<Button onClick={props.history.goBack}>Retour page précédente <span>sans modifier {param_human}</span></Button>
			</div>
		</div>
	}else if(editable && param_input === 'photo'){
		if(displayphoto){
			return <div className="edit photo-mode">
				{checkAuth(props.state)}

				<div className={'max-width-container'+(is_component?'':' no-padding')}>
					{is_component?<Product data={data} statedispatchcontext={StateDispatch} />:<Footer noborder infos={data} />}
				</div>
				<div className="max-width-container no-padding">



					<Button primary onClick={savephoto}>Enregistrer la modification</Button>

					<Button onClick={dontsavephoto}>Retour page précédente <span>sans modifier {param_human}</span></Button>
				</div>
			</div>
		}else{
			return <div className="edit photo-mode">
				{checkAuth(props.state)}
				<div className={'max-width-container'+(is_component?'':' no-padding')}>

					{is_component?<Product data={data} statedispatchcontext={StateDispatch} />:<Footer noborder infos={data} />}
				</div>

				<div className="max-width-container no-padding">


					<input type="file" accept="image/*" capture ref={photoinput_capture} />
					<input type="file" accept="image/*" ref={photoinput} />


					{mobile_device&&<Button primary onClick={()=>{photoinput_capture.current.click()}}>Prendre une photo</Button>}
					<Button primary onClick={()=>{photoinput.current.click()}}>Choisir une photo <span>parmi vos fichiers</span></Button>
					<Button primary onClick={()=>{setNewImgUrl(false)}}>Effacer la photo</Button>

					<Button onClick={props.history.goBack}>Retour page précédente <span>sans modifier {param_human}</span></Button>
				</div>
			</div>
		}
	}else{
		return (null);
	}
}


function EditInfos(props){

	useEffect(scrollTop);
	const [address, setAddress] = useState(false);


	return <div className="edit">
		{checkAuth(props.state)}
		<Footer noborder infos={props.state.infos} />


		<h2>Que souhaitez-vous faire ?</h2>
		{(!address)?(
		<div className="max-width-container no-padding">
			<Button primary to={'/modifier-coordonnees/nom'}>Modifier le nom</Button>
			<Button primary to={'/modifier-coordonnees/activité'}>Modifier l'activité</Button>
			<Button primary to={'/modifier-coordonnees/photo'}>Modifier la photo</Button>
			<Button primary to={'/modifier-coordonnees/téléphone'}>Modifier le numéro de téléphone</Button>
			<Button primary onClick={()=>{setAddress(true)}}>Modifier l'adresse</Button>
			<Button primary to={'/modifier-coordonnees/horaires'}>Modifier les horaires <span>et infos complémentaires</span></Button>

			<Button onClick={props.history.goBack}>Modifier autre chose</Button>
			<Button to="/">Retourner sur <span>votre site</span></Button>
		</div>
		):(
		<div className="max-width-container no-padding">
			<Button primary to={'/modifier-coordonnees/rue'}>Modifier la rue</Button>
			<Button primary to={'/modifier-coordonnees/cp'}>Modifier le code postal</Button>
			<Button primary to={'/modifier-coordonnees/commune'}>Modifier la commune</Button>
			<Button primary to={'/modifier-coordonnees/pays'}>Modifier le pays</Button>

			<Button onClick={()=>{setAddress(false)}}>Retour page précédente</Button>
			<Button to="/">Retourner sur <span>votre site</span></Button>
		</div>
		)}
	</div>
}


function ReorderComponents(props){

	useEffect(scrollTop,[]);
	const dispatch = useContext(StateDispatch);


	return (<div className="edit">
		{checkAuth(props.state)}
		<p>Reorganisez vos produits en glissant les boutons bleus vers le haut ou vers le bas</p>
		<div className="max-width-container no-padding">
		<ReorderComponentsList components={props.state.components} onChange={(ids) => {
            dispatch({type:'reorder_components',ids:ids});
        }}></ReorderComponentsList>
		<Button onClick={props.history.goBack}>Pour modifier autre chose</Button>
		<Button to="/">Pour retourner sur <span>votre site</span></Button>
		</div>
	</div>);
}


function App() {
	const [state, dispatch] = useReducer(stateReducer,init_state);
	useEffect(() => {
		dbLoad(dispatch);
	},[]);

	let basename = "/";
	if(document.location.pathname != '/' && document.location.pathname.includes('/r/')){
		basename = document.location.pathname.replace(/\/$/,"");
	}

	if(state.loaded){
		return <div className="app">
			<StateDispatch.Provider value={dispatch}>
				<Router basename={basename}>
					<Switch>
						<Route path="/identification" render={() => <Auth state={state} />} />

						<Route exact path="/partager" render={({history}) => <ShareWebsite state={state} history={history} />} />


						<Route path="/modifier" render={() => <Edit state={state} />} />



						<Route exact path="/modifier-produit" render={({history}) => <EditProducts mode={'edit-product'} state={state} history={history} />} />
						<Route exact path="/modifier-produit/:id" render={({match,history}) => <EditProduct state={state} id={match.params.id} history={history} />} />
						<Route exact path="/modifier-produit/:id/:param" render={({match,history}) => <Editor state={state} id={match.params.id} param={match.params.param} history={history} />} />



						<Route exact path="/ajouter-produit" render={({history}) => <EditProducts mode={'add-product'} state={state} history={history} />} />




						<Route exact path="/reorganiser-produit" render={({history}) => <ReorderComponents state={state} history={history} />} />




						<Route exact path="/cacher-produit" render={({history}) => <EditProducts mode={'mask-product'} state={state} history={history} />} />




						<Route exact path="/supprimer-produit" render={({history}) => <EditProducts mode={'delete-product'} state={state} history={history} />} />
						<Route exact path="/supprimer-produit/:id" render={({match,history}) => <DeleteProduct state={state} history={history} id={match.params.id} />} />




						<Route exact path="/modifier-coordonnees" render={({history}) => <EditInfos state={state} history={history} />} />
						<Route exact path="/modifier-coordonnees/:param" render={({match,history}) => <Editor state={state} param={match.params.param} history={history} />} />





						<Route exact path="/changer-code/:old/:new" render={({match}) => <ChangePassword state={state} old={match.params.old} new={match.params.new} />} />} />



						<Route render={() => <Public state={state} />} />
					</Switch>
				</Router>
			</StateDispatch.Provider>
		</div>
	}else{
		return <div className="app">
			Chargement ...
		</div>
	}
}

export default App;
