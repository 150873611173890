import React,{useContext} from 'react';
import { withRouter } from 'react-router';
import './Components.scss';
import ReactSortable from 'react-sortablejs';

function newlinesToBr(str){
	var nstr = str.replace("<","&lt;").replace(">","&gt;").replace(/\n/g," <br /> ");
	nstr = nstr.replace(/(\S+)@(\S+)/gi,function(match,p1,p2){
		return '<a href="mailto:'+match+'?subject=Contact">'+match+'</a>';
	});
	nstr = nstr.replace(/(\S+):\/\/(\S+)/gi,function(match,p1,p2){
		return '<a href="'+match+'" target="_blank" rel="noopener">'+match+'</a>';
	});
	return nstr;
}



export function ReorderComponentsList({components,onChange}){
	let sortable = null;
	const list_items = components.map(c => (
		<div className="line" key={c.id} data-id={c.id}>
			{(typeof c.img_url === 'string')&&<div className="img" style={{backgroundImage:'url('+c.img_url+')'}}></div>}
			<div className="text">{c.title}{c.masked?' (caché)':''}</div>
			<div className="handle"></div>
		</div>
	));
	return (
        <div className="reorder-components-list">
            <ReactSortable
                options={{
					animation: 150,
					ghostClass: 'ghost',
					handle: '.handle',
					opacity: 1
                }}
                ref={(c) => {
                    if (c) {
                        sortable = c.sortable;
                    }
                }}
				tag="div"
                onChange={(order, sortable, evt) => {
                    onChange(order);
                }}
            >
                {list_items}
            </ReactSortable>
        </div>
    );
}

export function ComponentsList(props){
	const dispatch = useContext(props.statedispatchcontext);

	const available_components = {
		product: Product
	};
	const components = props.components.map((component,i) => {
		const ComponentType = available_components[component.type];
		return (!component.masked || props.mode)?[
			<ComponentType data={component} key={component.id} mode={props.mode} statedispatchcontext={props.statedispatchcontext} />,
			<Space key={'s'+component.id} iBefore={i} mode={props.mode} statedispatchcontext={props.statedispatchcontext} />
		]:null;
	}).flat();

	return <div>
		<Space iBefore={-1} mode={props.mode} statedispatchcontext={props.statedispatchcontext} />
		{components}
	</div>
}

export function Space(props) {
	const dispatch = useContext(props.statedispatchcontext);
	if(props.mode === 'add-product'){
		return (
			<Button primary onClick={()=>dispatch({
				type: 'insert_product_at',
				i_before: props.iBefore
			})}>Pour ajouter un produit <span>à cet endroit</span></Button>
		)
	}
	return (
		<div className="component-space"></div>
	)
}

function ButtonView(props){
	const infos = props.infos;
	function onClick(){
		if(props.to){
			props.history.push(props.to);
		}else if(props.onClick){
			props.onClick();
		}
	}
	return (
		<div className={'component-btn'+(props.primary?' primary':'')+(props.red?' red':'')} onClick={onClick}>
			<div className="left">
				<p>{props.children}</p>
			</div>
			<div className="right">
			</div>
		</div>
	)
}
export const Button = withRouter(ButtonView);


export function Header(props) {
	const infos = props.infos;
	return (
		<div className={'component-header'+(props.fixed?' fixed':'')}>
			<div className="scroller">
				<div className="content">
					<h1 className={(props.infos.name.length < 13?'large':'')}>
						{infos.name}
					</h1>
					{infos.phone&&<a href={'tel:'+infos.phone}><span>{infos.phone}</span></a>}
				</div>
			</div>
			<div className="filler"></div>
		</div>
	)
}



// export function Title(props) {
// 	const data = props.data;
// 	return (
// 		<div className="component-title">
// 			<h2>{data.text}</h2>
// 		</div>
// 	)
// }



function ProductPrice(props){
	let price_num = props.price;
	if(price_num > 10000){
		price_num = Math.round(price_num);
	}
	let price = price_num.toFixed(2).replace(/\./g,'€').replace(/€00/g,'€');

	return (
		<p className="price">{price}</p>
	)
}
export function Product(props) {
	const dispatch = useContext(props.statedispatchcontext);
	const data = props.data;
	const has_img = (typeof data.img_url === 'string');
	let style = {};
	const masked = data.masked;

	let btns = [];
	if(props.mode === 'mask-product'){
		btns.push(<Button key={0} onClick={mask} primary>Pour {(masked?'re-afficher':'cacher')} <span>ce produit</span></Button>);
	}
	if(props.mode === 'delete-product'){
		btns.push(<Button key={1} red to={'/supprimer-produit/'+data.id}>Pour supprimer <span>ce produit</span></Button>);
	}
	if(props.mode === 'edit-product'){
		btns.push(<Button key={2} to={'/modifier-produit/'+data.id} primary>Pour modifier <span>ce produit</span></Button>);
	}

	style.minHeight = 30+(btns.length*100)+(masked?30:0);
	if(has_img){
		style.backgroundImage = 'url('+data.img_url+')';
		style.paddingTop = ((data.img_ratio||1)*100).toFixed(2)+'%';
	}

	function mask(){
		dispatch({
			type: 'set_prop_in',
			in: data,
			prop: 'masked',
			value: !(data.masked)
		});
	}

	return (
		<div className={'component-product'+(has_img?' img':'')+(masked?' masked':'')} style={style}>
			<div className="line-top">
				{data.title && <h3>{data.title}</h3>}
				{(data.price > 0) && <ProductPrice price={data.price}/>}
			</div>
			{data.desc && <div className="line-bottom">
				<p className="desc" dangerouslySetInnerHTML={{__html:newlinesToBr(data.desc)}}></p>
			</div>}
			{(data.sold) && <div className="sold-block"></div>}
			{(props.mode)&&(
				<div className={'btn-container'+(props.mode==='add-product'?' muted':'')}>
					{btns}
				</div>
			)}
			{masked&&<div className="masked-message">Ce produit caché du public</div>}
		</div>
	)
}






export function Footer(props) {
	const infos = props.infos;
	const has_img = (typeof infos.img_url === 'string');
	let style = {};
	if(has_img){
		style.backgroundImage = 'url('+infos.img_url+')';
	}
	const search_url = 'https://www.google.com/maps/search/?api=1&query='+encodeURIComponent(infos.street_adress+' '+infos.postal_code+' '+infos.locality+' '+infos.country);
	const direction_url = 'https://www.google.com/maps/dir/?api=1&destination='+encodeURIComponent(infos.street_adress+' '+infos.postal_code+' '+infos.locality+' '+infos.country);
	return (
		<div className={'component-footer'+(props.noborder?' no-border':'')}>
			<div className="content">
				{has_img?<div className="img" style={style}></div>:''}
				<h2>{infos.name}</h2>
				<p><b>{infos.activity}</b></p>
				<p>{infos.phone}</p>
				<p>{infos.street_adress} <br/>
				{infos.postal_code} {infos.locality} <br/>
				{infos.country}</p>
				<p>Itinéraire : <a href={direction_url} className="uppercase" target="_blank" rel="noopener noreferrer">cliquez ici</a></p>
				{infos.notes && <p className="notes" dangerouslySetInnerHTML={{__html:newlinesToBr(infos.notes)}}></p>}
			</div>
		</div>
	)
}
//<p className="copyright">&copy; {infos.name} {new Date().getFullYear()}</p>
